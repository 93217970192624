<template>
    <div class="page-not-found-component-container mt-5">
       
        <img v-if="templateMode < 3" class="mt-5 mb-5" src="@/assets/icons/page404.png" alt="bébé qui pleure">
        <img v-if="templateMode == 3" class="mt-1 mb-5" src="@/assets/icons/page404.png" alt="bébé qui pleure">
        <div class="back-to-menu">
            <p class="mb-4">Cette page n'existe pas</p>
           <!-- <router-link to="/">Retour au l'acceuil</router-link>-->
        </div>
    </div>
</template>

<script>
export default {
    name: "PageNotFoundComponent",
    components: {
    },
    props: ['templateMode'],
};
</script>

<style lang="scss">
    @import "page-not-found-component.scss";
</style>